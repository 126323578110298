import React, {useEffect, useState} from "react";
import {Link, Navigate, useSearchParams} from 'react-router-dom';
import {login, getSession, FetchResult, getUser, getSubscriptions, getBuildList} from '../shared/authentication';
import { redirectToUri, validateEmail } from '../shared/utils';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Dashboard from "./Dashboard/Dashboard";



function Login (){

    const [userDetails, setUserDetails] = useState();
    const [subscriptionDetails, setSubscriptionDetails] = useState();
    const [buildList, setBuildList] = useState<any | null>();
    const [tokensVerified, setTokensVerified] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams ();
    const [formData, setFormData] = useState(
        {
            email:"",
            emailFeedback:"",
            emailValid:false,
            password:"",
            passwordFeedback:"Please enter your password.",
            passwordValid:false,
            formValidated:false,
            isLoggingIn:false,
            loginFailed:false,
            loginFeedback:"",
            tokensVerified:false,
            passwordVisible:false
        }
    )



    useEffect(()=>{
        document.title = 'Log in | Stratbox';
        // verifyTokens().then(result => {
        //     setTokensVerified(result);
        // });
    }, []);


    function handleChange(event: React.ChangeEvent<HTMLInputElement>){
        console.log("handleChange");

        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    function validateForm(callback: Function){
        var emailFeedback = "Please enter your email.";
        var emailValid = false;
        if( formData.email.length > 0){
            emailValid = validateEmail(formData.email);
            if(!emailValid) emailFeedback = "Please enter a valid email.";
        }
        var passwordValid = formData.password.length > 0;

        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                formValidated: true,
                emailValid: emailValid,
                emailFeedback: emailFeedback,
                passwordValid: passwordValid,
            }
        });

        callback(emailValid && passwordValid);
    }

    function showPassword(){
        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                passwordVisible: true,
            }
        });
    }

    function hidePassword(){
        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                passwordVisible: false,
            }
        });
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();
        if(formData.isLoggingIn)return;
        validateForm(async (success: boolean)=>{
            if(success){
                setFormData(prevFormData=>{
                    return {
                        ...prevFormData,
                        isLoggingIn: true,
                        loginFailed:false,
                    }
                });

                const {error,payload} = await login(formData.email, formData.password);

                if(error){
                    setFormData(prevFormData=>{
                        return {
                            ...prevFormData,
                            isLoggingIn: false,
                            loginFailed: true,
                            loginFeedback: error
                        }
                    });
                }

                else{

                    console.log(payload);

                    setUserDetails(payload.user);
                    setSubscriptionDetails(payload.subscriptions);

                    // setTokensVerified(true);

                    // verifyTokens();

                    // tokensVerified = true;

                    getBuildList().then(({error, payload})=>{
                        console.log("getBuildListResult:");
                        console.log(`Error:`);
                        console.log(error);
                        console.log(`Payload:`);
                        console.log(payload);

                        if (error === null) {
                            //@ts-ignore
                            setBuildList(payload.payload);
                        }
                        setTokensVerified(true);
                    });

                    

                    // return (
                    //     <Dashboard
                    //         user={payload.user}
                    //         subscriptions={payload.subscriptions}
                    //     />
                    // )
                    // redirectToUri(searchParams, payload.redirectUri);
                }
            }
        })
    }

    function renderLoginForm(){
        return(
            <div className="flex-container">
                <div>
                    <Header/>
                    <section className="sc-login p-4">
                        <div className="login-title">Log in to your account</div>
                        {formData.loginFailed &&
                            <div className="alert alert-danger mt-3" role="alert">
                                {formData.loginFeedback}
                            </div>
                        }
                        <form id="form-login" onSubmit={handleSubmit} noValidate>
                            <div id="email-field">
                                <input 
                                    type="email" 
                                    placeholder="Enter email" 
                                    id="email"
                                    className={`form-control ${(formData.formValidated && !formData.emailValid)?"is-invalid":""} text-09`}
                                    name="email"
                                    onChange={handleChange}
                                    value={formData.email}
                                />
                                <div className="invalid-feedback">
                                    {formData.emailFeedback}
                                </div>
                                <div className=" mb-3"></div>
                            </div>
                            
                            <div id="password-field">
                                <div className="input-group">
                                    <input 
                                        type={formData.passwordVisible?"text":"password"} 
                                        placeholder="Enter password" 
                                        id="password"
                                        className={`form-control ${(formData.formValidated && !formData.passwordValid)?"is-invalid":""} text-09`}
                                        name="password"
                                        onChange={handleChange}
                                        value={formData.password}
                                    />
               
                                    <div className="password-visibility" onMouseDown={showPassword} onMouseUp={hidePassword} onMouseLeave={hidePassword}><i className={`bi ${formData.passwordVisible?"bi-eye":"bi-eye-slash"}`}></i></div>
                        
                                </div>
                                <div className="invalid-feedback">
                                    {formData.passwordFeedback}
                                </div>
                                <Link to="/forgot-password" className="link">Forgot your password?</Link>
                                <div className=" mb-3"></div>
                            </div>
                            
                            <div>
                                <button type="submit" id="login-submit" className="btn btn-stbx-primary form-control">
                                    {formData.isLoggingIn
                                        ? <div className="spinner-border spinner-border-sm text-light" role="status">
                                        </div>
                                        : <span>Log in</span>
                                    }
                                </button>
                                
                            </div>
                        </form>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "1rem",
                            }}
                        >
                            <div
                                style={{
                                    // color: "darkgrey",
                                    fontWeight: "500",
                                    fontSize: "0.9375rem",
                                    margin: "0.25rem"
                                }}
                            >
                                Don't have an account?
                            </div>
                            <div
                                style={{
                                    color: "#2185d0",
                                    fontWeight: "500",
                                    fontSize: "0.9375rem",
                                    margin: "0.25rem",
                                    cursor: "pointer"
                                }}
                                onClick={() => window.location.href = "https://store.imagedreality.com/signup"}
                            >
                                Sign up
                            </div>
                        </div>

                    </section>
                </div>
                <Footer/>
            </div>
        );
    }

    function renderVerifyTokens(){

        console.log("renderVerifyTokens");


        // return <Navigate to="/dashboard" />

        // verifyTokens();
        // return(<div></div>)
        return(
            <Dashboard
                userDetails={userDetails}
                subscriptionDetails={subscriptionDetails}
                buildList={buildList}
            />
        )
    }

    // async function verifyTokens(){
    //
    //     console.log("verifyTokens");
    //
    //     const {error,payload} = await getSession();
    //
    //     if(error !== null){
    //
    //         console.log("verifyTokens: Error");
    //         console.log(error);
    //
    //         setFormData(prevFormData=> {
    //             return {
    //                 ...prevFormData,
    //                 tokensVerified:false
    //             }
    //         });
    //         return false;
    //     }
    //     else{
    //         console.log("verifyTokens: Success");
    //
    //         await getUser().then(({error, payload}) => {
    //             setUserDetails(payload);
    //         })
    //
    //         await getSubscriptions().then(({result, payload}) => {
    //             setSubscriptionDetails(result.payload);
    //         })
    //         return true;
    //     }
    // }


    return(
        <>
            {/*{!formData.tokensVerified? renderLoginForm() : renderVerifyTokens()}*/}
            {!tokensVerified? renderLoginForm() : renderVerifyTokens()}
        </>
    )
}

export default Login;