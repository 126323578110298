import irLogo from '../img/ir_logo.png';

function Footer (){

    return(
        <footer>
            <div className="footer-logo">
                <img src={irLogo}></img>
            </div>  
        </footer>
    );
}

export default Footer;