const apiUrl =  process.env.REACT_APP_STBX_API_URL || "";
const signupPath = process.env.REACT_APP_STBX_SIGNUP_PATH || "";

export async function getInvitation(invitation_id:string){
    if(invitation_id){
        const response = await fetch(apiUrl + signupPath+"/get-invitation", {
            method: 'POST',
            headers: {'Content-Type': 'application/json' },
            body: JSON.stringify({"invitation_id": invitation_id})
        });

        if(!response.ok){
            return {error:"An error has occurred: "+response.status, invitation:null};
        }

        const result = await response.json();
        return {error:result.error, invitation:result.payload};
    }
    else {
        return {error:"Bad parameter: No invitation id", invitation:null};
    }
}

export async function join(invitation_id:string, email: string, organisation_name: string, first_name:string, last_name:string, password:string){
    
    const response = await fetch(apiUrl + signupPath+"/join", {
       method: 'POST',
       headers: {'Content-Type': 'application/json' },
       body: JSON.stringify(
        {"invitation_id": invitation_id,
        "email": email,
        "organisation_name": organisation_name,
        "first_name": first_name,
        "last_name": last_name,
        "password": password
        })
   });

    if(!response.ok){
        return {error:"An error has occurred: "+response.status, success:false};
    }

    const result = await response.json();
    return {error:result.error, success:result.payload}
}

export async function verify(email:string, verification_code:string){
    const response = await fetch(apiUrl + signupPath+"/verify", {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify({"email": email, "verification_code": verification_code})
    });
    
    if(!response.ok){
        return "An error has occurred: "+response.status;
    }
    const result = await response.json();
    return result.error
}