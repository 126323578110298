import Cookies from "js-cookie";
import {json} from "stream/consumers";

const apiUrl =  process.env.REACT_APP_STBX_API_URL || "";
const authPath = process.env.REACT_APP_STBX_AUTH_PATH || "";
const userPath = process.env.REACT_APP_STBX_USER_PATH || "";
const buildsPath = process.env.REACT_APP_STBX_BUILDS_PATH || "";
const subsPath = process.env.REACT_APP_STBX_SUBS_PATH || "";

export type FetchResult = {
    error: string,
    payload: string
};


export async function login(username: string, password: string){
    const response = await fetch(apiUrl + authPath+"/login", {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({"username": username, "password": password})
    });

    if (!response.ok) {
        const result = await response.json();
        const error = result.error;
        return { error: "An error has occurred: " + error, payload: null };
    }

    const result = await response.json();
    return result;
}

export async function logout(){
    let idToken = Cookies.get(`id_token`);

    const response = await fetch(apiUrl + authPath+"/logout", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${idToken}`
        },
        credentials: 'include',
        body: JSON.stringify({
            application: "stbx_cor"
        })
    });

    console.log(response);
    
    if(!response.ok){
        return "An error has occurred: "+response.status;
    }

    const result = await response.json();
    return result.error;



    // let idToken = Cookies.get(`id_token`);
    //
    // try {
    //
    //     // setTimeout(window.location.href = process.env.REACT_APP_AUTH_URL + "/logout", 500);
    //
    //     await fetch("https://api.analytics.imagedreality.com/auth/logout", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${idToken}`
    //         },
    //         credentials: "include",
    //         body: JSON.stringify({
    //             application: "stbx_cor"
    //         })
    //     }).then(status)
    //       .then(json)
    //       .then(data => {
    //           console.log(data);
    //
    //           if (data.redirectUri) {
    //               window.location.href = data.redirectUri;
    //           }
    //           else {
    //               window.location.reload();
    //           }
    //       })
    //       .catch(error => {
    //           console.log(error);
    //           window.location.reload();
    //       });
    //
    // } catch (e) {
    //     console.error(`Error while signing out: could not remove all cookies - ${e}`);
    // }
}

export async function getSession(){
    console.log("getSession() POST...")

    try {
        const response = await fetch(apiUrl + authPath + "/refresh", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        });

        if (!response.ok) {
            return {error: "An error has occurred: " + response.status, payload: null};
        }

        const result = await response.json();

        // console.log(result);

        return {error: null, payload: result};
    }
    catch (e) {
        return {error: e, payload: null};
    }
}

export async function getUser() {
    console.log("getUser() POST...")

    try {
        const response = await fetch(apiUrl + userPath, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        });

        if(!response.ok){
            return {error: "An error has occurred: "+response.status, payload:null};
        }

        console.log(response);

        const result = await response.json();
        return {error: null, payload: result};
    }
    catch (e) {
        return {error: e, payload: null};
    }


}

export async function getSubscriptions(){
    console.log("getSubscriptions() POST...")

    try {
        const response = await fetch(apiUrl + subsPath, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        });

        if (!response.ok) {
            return {error: "An error has occurred: " + response.status, payload: null};
        }

        const result = await response.json();

        // console.log(result);

        return {error: null, payload: result};
    }
    catch (e) {
        return {error: e, payload: null};
    }
}


export async function getBuildList() {
    console.log("getBuildList() GET...")

    try {
        console.log("getBuildList at: ", (apiUrl + buildsPath));
        const response = await fetch(apiUrl + buildsPath, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        });

        if(!response.ok){
            return {error: "An error has occurred: "+response.status, payload:null};
        }

        console.log("getBuildList response: ",response);

        const result = await response.json();

        return {error: null, payload: result};
    }
    catch (e) {
        console.log("getBuildList catch: ",e);
        return {error: e, payload: null};
    }
}