// © Imaged Reality Ltd 2022. All Rights Reserved

// import LoadingOverlay from "react-loading-overlay";
import { useEffect, useRef, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { ToastContainer } from "react-toastify";
// import { refreshToken, setSeatsInUse, setTotalSeats } from "../features/authentication/authenticationSlice";
// import { debounce } from "../utils";
// import { ReactTooltip } from "react-tooltip";
// import { GlobalDebug } from "../remove-consoles";
// import { Icon } from "semantic-ui-react";


import stbxLogo from '../../img/stratbox_logo.webp';
import irLogo from '../../img/ir_logo.webp';

import "../../administration.css";
import Cookies from "js-cookie";

function Administration(props) {
  // Fix for "Failed prop type: LoadingOverlayWrapper: prop type `styles.content` is invalid" :
  // ... suggested to use @ronchalant/react-loading-overlay isntead
  // LoadingOverlay.propTypes = undefined;

  // PROPS:
  // rawAttributes={result}
  // email={result.email}
  // fName={result.name}
  // lName={result.family_name}
  // organization={"Imaged Reality"}
  // seatCount={7}

  // const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  // const organization = useSelector((state) => state.authentication.organizationName);
  // const [seatCount, updateSeatCount] = useState(props.seatCount);

  // const [seatCount, updateSeatCount] = useState(0);
  const [organization, setOrganization] = useState("");

  const inviteEmail = useRef("");
  const [sendButtonState, setSendButtonState] = useState(false);
  const [revokeButtonState, setRevokeButtonState] = useState(true);
  const [cancelButtonState, setCancelButtonState] = useState(true);
  const [editOrgButtonState, setEditOrgButtonState] = useState(false);
  const newOrgName = useRef("");
  const [invitationResponseMessage, setInvitationResponseMessage] = useState("");
  const [revokeResponseMessage, setRevokeResponseMessage] = useState("");
  const [cancelResponseMessage, setCancelResponseMessage] = useState("");
  const [organizationNameEditMode, setOrganizationNameEditMode] = useState(false);
  const [copiedNotif, setCopiedNotif] = useState("");
  const [users, updateUsers] = useState([]);
  const [activations, updateActivations] = useState([]);
  const [invitations, updateInvitations] = useState([]);
  const [totalSeats, setTotalSeats] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);



  // const organisationName = useSelector((state) => state.authentication.organizationName);
  // const totalSeats = useSelector((state) => state.authentication.totalSeats);

  console.log(`organisationName: ${props.organisationName}`);
  console.log(`totalSeats: ${totalSeats}`);

  // <p>{subscription.productName === "Core Explorer"? Number(Object.entries(users).filter(user => user.email in Object.values(activations)).length) : Number(subscription.seatCount)}</p>

  console.log(Object.values(users))
  console.log(users)
  console.log(Object.values(activations))
  console.log(activations)

  // console.log(Object.values(users).filter(user => Object.values(activations).includes(act => act.email === user.email)).length)
  // console.log(activations.reduce((act, act2) => act.email !== act2.email));

    console.log([...new Set(activations.map((item) => item.email))])


  let bordersEnabled = false;

  let icon_edit = require(`../../img/Edit.png`);
  let icon_back = require(`../../img/Back.png`);
  let icon_check = require(`../../img/Confirm.png`);

  let debugModeEnabled = true
  // if (debugModeEnabled) {
  //   GlobalDebug(true);
  // } else {
  //   GlobalDebug(false);
  // }


  function debounce(fn, ms) {
      let timer;
      return _ => {
          clearTimeout(timer);
          timer = setTimeout(_ => {
              timer = null;
              fn.apply(this, arguments);
          }, ms);
      };
  }

  useEffect(() => {

    console.log("UE: debugModeEnabled ");
    // if (process.env.NODE_ENV === "production" || process.env.REACT_APP_ENV === "STAGING" || !debugModeEnabled) {
    // if (!debugModeEnabled) {
    //   GlobalDebug(true);
    // } else {
    //   GlobalDebug(true);
    // }
  }, [debugModeEnabled]);


  // Listeners for scaling & re-positioning components when browser window is resized
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [innerheight, setInnerHeight] = useState(window.innerHeight);

  console.log(`WINDOW WIDTH: ${innerWidth}`);
  console.log(`WINDOW HEIGHT: ${innerheight}`);

  useEffect(() => {
    console.log("UE: [] ");

    document.title = 'Administration | Stratbox';

    const debouncedHandleResize = debounce(function handleResize() {
      setInnerWidth(window.innerWidth);
      setInnerHeight(window.innerHeight);
    }, 100);
    window.addEventListener("resize", debouncedHandleResize);
    return _ => {
      window.removeEventListener("resize", debouncedHandleResize);
    };

  });

  // Start the refresh token loop:
  const refreshLoopStarted = useRef(false);
  const refreshCountdown = useRef(2400000);

  const gotOrgInfo = useRef(false);


  useEffect( () => {
    console.log("UE: [] ");
    if (gotOrgInfo.current === false) {
      gotOrgInfo.current = true;

      getOrganisationInfo();
    }


    // if (refreshLoopStarted.current === false) {
    //   refreshLoopStarted.current = true;
    //
    //   refreshAfterTimeout();
    //   console.log("Started the Token Refresh loop");
    // }
  }, []);




  function status(response) {
    if (response.status >= 200 && response.status < 300) {
      return (response);
    } else {
      return (response.statusText);
    }
  }

  function json(response) {
    return response.json();
  }



  function getOrganisationInfo() {

    const idToken = Cookies.get(`id_token`) || "";
    const apiUrl = process.env.REACT_APP_STBX_API_URL || "";
    const orgPath = process.env.REACT_APP_STBX_ORG_PATH || "/organisation";

    console.log("sending getOrgInfo...");

    fetch(apiUrl + orgPath,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `${idToken}`
        },
        credentials: "include"
      })
      .then(status)
      .then(json)
      .then(data => {
        console.log(data);

        // USERS:
        try {
          data.payload.users.forEach(user => {
            console.log(user);

            updateUsers(users => [...users,
              {
                fName: user.first_name,
                lName: user.last_name,
                email: user.email
              }
            ])
          });
        }
        catch (e) {
          console.log("Error assigning user state...")
          console.error(e);
        }

        // ACTIVATIONS:
        try {
          data.payload.activations.forEach(activeUser => {

            console.log(activeUser);
            updateActivations(activations => [...activations,
              {
                email: activeUser.email,
                lastActive: "",
                appSubId: activeUser.app_sub_id,
              }
            ])
          });
        }
        catch (e) {
          console.log("Error updating activations...")
          console.error(e);
        }

        // INVITATIONS:
        try {
          data.payload.invitations.forEach(invitedUser => {

            console.log(invitedUser);

            if (invitations.findIndex(invite => invite.email === invitedUser.email) === -1) {
              updateInvitations(invitations => [...invitations,
                {
                  email: invitedUser.email,
                  expiryDate: invitedUser.expiry_date
                }
              ])
            }
          });
        }
        catch (e) {
          console.log("Error updating invitations...")
          console.error(e);
        }

        // SUBSCRIPTIONS:
        try {
          data.payload.subscriptions.forEach(subscription => {

            setSubscriptions(subscriptions => [...subscriptions, {
              id: subscription.id,
              productName: subscription.app,
              startDate: subscription.start_date,
              endDate: subscription.end_date,
              plan: subscription.plan,
              seatCount: subscription.seat_count
            }]);

            if (subscription.app === "Core Explorer") {

              setTotalSeats(subscription.seat_count);

            }
          });
        }
        catch (e) {
          console.log("Error updating invitations...")
          console.error(e);
        }

      })
      .catch(error => {
        console.error(error);
      });
  }



  // Refresh the auth token after timeout:
  // function refreshAfterTimeout() {
  //   setTimeout(() => {
  //     refreshToken().then(onSuccess => {
  //       console.log('Successfully refreshed token');
  //       console.log(onSuccess);
  //     }).catch(error => {
  //       console.log('Failed to refresh token - DO REDIRECT OR RETRY HERE');
  //       console.log(error)
  //       switch (refreshCountdown.current) {
  //
  //         //  T + 40 mins:
  //         case 2400000:
  //           console.log('Trying again in 15 minutes');
  //           refreshCountdown.current = 900000;
  //           refreshAfterTimeout();
  //           break;
  //
  //         //  T + 55 mins:
  //         case 900000:
  //           console.log('Trying again in 3 minutes');
  //           refreshCountdown.current = 180000;
  //           refreshAfterTimeout();
  //           break;
  //
  //         //  T + 58 mins:
  //         case 180000:
  //           console.log('Token refresh failed for the 3rd time (t+58 mins). Redirecting to auth page in 30 seconds...');
  //           setTimeout(() => window.location.href = process.env.REACT_APP_AUTH_URL, 30000);
  //           break;
  //       }
  //     });
  //   }, refreshCountdown.current);
  // }



  // function validateEmail() {
  //   const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  //   return (regex.test(inviteEmail.current));
  // }

    function validateEmail(email) {
        const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        return (regex.test(email.trim().toLowerCase()));
    }

  function validateOrganization() {
    // const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    // return (regex.test(inviteEmail));

    console.log(newOrgName.current)
    if (newOrgName.current.length > 0) {
      return true;
    }
    else return false;
  }


  async function sendInvitation(emails) {

      let emailList = emails.split(",");

      console.log(emailList);

      emailList.forEach((email) => {
          console.log(validateEmail(email))

          if (!validateEmail(email)) {
              window.alert("Invalid address format");
              return;
          }
      });


    const idToken = Cookies.get(`id_token`) || "";
    const apiUrl = process.env.REACT_APP_STBX_API_URL || "";
    const orgPath = process.env.REACT_APP_STBX_ORG_PATH || "";

    console.log(`Bearer ${idToken}`);
    console.log("sending...");
    setSendButtonState("sending");

    try {
      const response = await fetch(apiUrl + orgPath + "/invite",
          {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              "Authorization": `${idToken}`
          },
          body: JSON.stringify({ "emails": emailList }),
          credentials: "include"
      });

      console.log(response);

      if(!response.ok){

          const result = await response.json();

          console.log(result.error);
          setSendButtonState(false)
          inviteEmail.current = "";
          document.getElementById('invite-email-input').disabled = false;

          setInvitationResponseMessage(<p>{result.error}</p>);

          setTimeout( () => {
              setInvitationResponseMessage("");
          }, 15000);
      }

      else {
          const result = await response.json();


          setSendButtonState(false)
          inviteEmail.current = "";
          document.getElementById('invite-email-input').disabled = false;

          setInvitationResponseMessage(

              <div
                  style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                  }}
                  onClick={() => {
                      navigator.clipboard.writeText(result.payload.invitation_link);
                      setInvitationResponseMessage(<p><i>copied to clipboard</i></p>);

                      setTimeout(function() {
                          setCopiedNotif("");
                          console.log("**");
                      }, 750);
                  }}
              >

                  <p style={{display: "inline-block", textAlign: "left", minHeight: "2.6rem"}}> {copiedNotif === "" || copiedNotif["link"] === undefined? result.payload.invitation_link : copiedNotif["link"]}</p>

                  <div
                      style={{alignSelf: "center", display: "inline-block", minHeight: "2.6rem"}}
                  />

              </div>
          );

          if (invitations.findIndex(invite => invite.email === emailList[0]) === -1) {
              updateInvitations(invitations =>
                  [...invitations, {
                      email: emailList[0],
                  }]
              );
          }

          setTimeout( () => {
              setInvitationResponseMessage("");
          }, 30000);

      }

    }
    catch (e) {
        console.log("Unexpected error creating the invitation link");
        console.log(e)

        setSendButtonState(false)
        inviteEmail.current = "";
        document.getElementById('invite-email-input').disabled = false;

        setInvitationResponseMessage(`<p>There was an unexpected error while fetching a new invite. Please contact support@imagedreality.com</p>`);

        setTimeout( () => {
            setInvitationResponseMessage("");
        }, 15000);
    }
  }

  async function revokeUser(userInfo, index) {

    console.log(userInfo);

    setRevokeButtonState({[index]: "sending"});
    await fetch('',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'x-api-key': `${apiKey}`,
        },
      })
      // .then(status)
      // .then(json)
      .then(response => {

      });

    setTimeout(() => {
      setRevokeButtonState(true)
      setRevokeResponseMessage(<p><b>{userInfo.fName + " " + userInfo.lName}</b> has been removed from the organization</p>);
      updateUsers(users.filter(user => user.email !== userInfo.email));
      setTimeout( () => setRevokeResponseMessage(""), 5000);
    }, 1500);
  }

  async function cancelInvitation(userInfo, index) {

    console.log(userInfo);

    setCancelButtonState({[index]: "sending"});
    // await fetch('',
    //   {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       // 'x-api-key': `${apiKey}`,
    //     },
    //   })
    //   // .then(status)
    //   // .then(json)
    //   .then(response => {
    //
    //   });

    setTimeout(() => {
      setCancelButtonState(true)
      setCancelResponseMessage(<p>The invitation for <b>{userInfo.email}</b> has been invalidated</p>);
      updateInvitations(invitations => [...invitations.filter(user => user.email !== userInfo.email)]);
      setTimeout( () => setCancelResponseMessage(""), 5000);
    }, 1500);
  }

  async function changeOrganizationName() {
    setEditOrgButtonState("sending");

    await fetch('',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'x-api-key': `${apiKey}`,
        },
      })
      // .then(status)
      // .then(json)
      .then(response => {

      });
    setTimeout(() => {
      setOrganization(newOrgName.current);
      setOrganizationNameEditMode(false);
      setEditOrgButtonState(false);
    }, 3000);
  }



  function fetchPendingInvites() {
  }

  function fetchOrganizationUsers() {
  }



  console.log(`rendering Management`);
  return (
    <div className="Management">

      {/*<ReactTooltip delayShow={500} place="top" type="info" effect="solid" />*/}
      <title>Organization Management</title>

        <div
          id={'page-container'}
          style={{
          height: "100vh",
          display: "flex",
          lineHeight: "normal",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#fafbfc",
          overflow: "hidden",
        }}
        >

          {/* HEADER */}
          <div
            style={{
              maxHeight: "8vh",
              minHeight: "5rem",
              display: "flex",
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <img
              className={"backButton"}
              style={{
                marginLeft: "1.25rem",
                marginTop: "4.25rem",
                alignSelf: "center",
                alignItems: "flex-start",
                alignContent: "flex-start",
                justifyContent: "flex-start",

                height: "2rem",
                width: "2rem",
                position: "fixed",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = process.env.REACT_APP_STBX_HOME_URL;
              }}
              src={icon_back}
            />

            <div
              className="header-logo"
              style={{
                width: "100%",
              }}
            >

              <img
                className="header-logo-img"
                src={stbxLogo}
                draggable="false"
              >
              </img>

            </div>
          </div>

          {/* BODY */}
          <div
            id={"body"}
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "2rem",
              maxHeight: "85vh",
              overflowY: "scroll",
              boxSizing: "content-box",
              width: "100%",
              maxWidth: "800px",
              backgroundColor: "#fafbfc",
              boxShadow: "2px 2px 18px rgba(0,0,0,0.1)",
              borderRadius: "8px",
              color: "#333333"
            }}
          >

            {!organizationNameEditMode || setEditOrgButtonState === 'sending'?
              <div
                id={"org-name"}
                style={{
                  alignSelf: "center",
                  textAlign: "left",
                  display: "flex",
                  height: "0rem",
                  marginTop: "1.25rem",
                  transform: "translateY(-2.5rem)",
                  marginBottom: "1rem",
                }}
              >
                <h1>{props.organisationName}</h1>
              </div>
              :
              <div
                id={"org-name"}
                style={{
                  alignSelf: "center",
                  textAlign: "left",
                  border: bordersEnabled? "solid 1px darkgray" : "none",
                  display: "flex",
                  flexWrap: "wrap",
                  height: "3rem",
                  marginBottom: "1rem",
                }}
              >
                <input
                  id={"edit-organization"}
                  name={"edit-organization"}
                  type={"text"}
                  // value={organization}
                  style={{
                    fontSize: "1.2rem",
                    paddingLeft: "1rem",
                    height:"2.4rem",
                    border: "none",
                    borderRadius: "4px",
                    backgroundColor: "#e9effe",
                    flexGrow: "2",
                    marginLeft: "3rem",
                    marginBottom: "0.25rem",

                    boxShadow: "1px 1px 2px white,  inset 1px 1px 2px rgba(0,0,0,0.25)",
                  }}
                  onChange={(event) => {
                    console.log(event.target.value);

                      newOrgName.current = event.target.value;


                      console.log(validateOrganization(event.target.value));

                      if (validateOrganization(event.target.value)) {
                        setEditOrgButtonState(true);
                      }
                      else {
                        setEditOrgButtonState(false);
                      }
                  }}
                  placeholder={"new organization name..."}
                >
                </input>

                <div
                  id={"send-invite-button"}
                  className={editOrgButtonState === true? "send-invite-button" : "send-invite-button disabled"}
                  style={{
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                    height: "2.4rem",
                    minWidth: "6.2rem",
                    borderRadius: "4px",
                    color: "#f4f4f4",
                    fontSize: "1rem",
                    textAlign: "center",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexGrow: "1",
                  }}
                  onClick={() => {
                    if (editOrgButtonState !== true) {
                      return;
                    }
                    changeOrganizationName()
                  }}
                >
                  {editOrgButtonState === "sending"?
                    <div className="lds-dual-ring"></div>
                    :
                    <b>CONFIRM</b>
                  }
                </div>

                <div
                  id={"send-invite-button"}
                  className={ "revoke-seat-button"}
                  style={{
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                    height: "2.4rem",
                    minWidth: "6.2rem",
                    borderRadius: "4px",
                    display: "flex",
                    color: "#f4f4f4",
                    fontSize: "1rem",
                    textAlign: "center",
                    padding: "0.25rem",
                    cursor: "pointer",
                    justifyContent: "center",
                    alignItems: "center",
                    flexGrow: "1",
                  }}
                  onClick={() => {
                    setOrganizationNameEditMode(false);
                  }}
                >
                    <b>CANCEL</b>
                </div>
              </div>
            }


            <div
              id={"subscriptions-container"}
              style={{
                marginTop: "2rem",
                boxShadow: "-2.5px -2.5px 4px white,  2px 2px 4px rgba(0,0,0,0.08)",
                borderRadius: "6px",
                padding: "2rem",
              }}
            >
              <h3>Subscriptions: </h3>

              <div
                id={"subscription-info"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignContent: "center",
                  textAlign: "left",
                  gap: "1.5rem",
                  flexWrap: "wrap"
                }}
              >
                {subscriptions.map((subscription, index) => {
                  return (
                  <div
                    id={`subscription-box-${index}`}
                    style={{
                      display: "flex",
                      filter: "drop-shadow(-0.25px -0.25px 0.25px hsl(118,10%,87%)) drop-shadow(2.5px 2.5px 1px rgba(255,255,255,1.0)",
                      flexDirection: "column",
                      fontSize: "1.2rem",
                      flexGrow: "1",
                      padding: "0.5rem 1.5rem 1rem 1.5rem",
                      backgroundColor: "#f8f8f8",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          color: "#56a2cf",
                          fontSize: "1.4rem",
                        }}
                      >
                        <p><b>{subscription.productName}</b></p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "12rem",
                        }}
                      >
                        <p>License type:</p>
                      </div>
                      <div
                        style={{
                          marginLeft: "1rem",
                          fontWeight: "500",
                          fontSize: "1.2rem",
                        }}
                      >
                        <p>{subscription.plan}</p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "12rem",
                        }}
                      >
                        <p>Start date:</p>
                      </div>
                      <div
                        style={{
                          marginLeft: "1rem",
                          fontWeight: "500",
                          fontSize: "1.2rem",
                        }}
                      >
                        <p>{new Date(subscription.startDate).toLocaleDateString('en-GB')}</p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "12rem",
                        }}
                      >
                        <p>End date:</p>
                      </div>
                      <div
                        style={{
                          marginLeft: "1rem",
                          fontWeight: "500",
                          fontSize: "1.2rem",
                        }}
                      >
                        <p>{new Date(subscription.endDate).toLocaleDateString('en-GB')}</p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "12rem",
                        }}
                      >
                        <p>Seats count:</p>
                      </div>
                      <div
                        style={{
                          marginLeft: "1rem",
                          fontWeight: "500",
                          fontSize: "1.2rem",
                        }}
                      >
                        <p>{subscription.seatCount}</p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "12rem",
                        }}
                      >
                        <p>Seats available:</p>
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          marginLeft: "1rem",
                          fontWeight: "500",
                          fontSize: "1.2rem",
                        }}
                      >
                          <p>{Number(subscription.seatCount) -
                              (activations.filter((item) => item.appSubId === subscription.id).length)
                          }</p>
                      </div>
                    </div>

                  </div>
                  )

                })}


                {/*<div*/}
                {/*  id={"revoke-seat-header"}*/}
                {/*  style={{*/}
                {/*    fontSize: "1.2rem",*/}
                {/*    flexGrow: "2",*/}
                {/*    width: "15%",*/}
                {/*    minWidth: "6.2rem",*/}
                {/*    border: bordersEnabled? "solid 1px darkgray" : "none",*/}
                {/*    marginLeft: "1rem",*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <p><strong></strong></p>*/}
                {/*</div>*/}
              </div>

              {/*<div*/}
              {/*  style={{*/}
              {/*    fontSize: "1.2rem",*/}
              {/*    textAlign: "center",*/}
              {/*    minHeight: "1rem",*/}
              {/*    marginTop: "1rem",*/}
              {/*    color: "#56a2cf",*/}
              {/*  }}>*/}
              {/*</div>*/}
            </div>

            <div
              id={"invitation-container"}
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "left",
                fontSize: "1.4rem",
                border: bordersEnabled? "solid 2px gray" : "none",
                width: "100%",
                marginTop: "2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  boxShadow: "-2.5px -2.5px 4px white,  2px 2px 4px rgba(0,0,0,0.08)",
                  borderRadius: "6px",
                  padding: "2rem",
                  // border: "solid 2px gray"
                }}
              >
                <div
                  id={"create-invitation-container"}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    // border: "solid 2px yellow",

                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <h3>Invite new user: </h3>

                    <div
                      id={"seat-info"}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        // justifyContent: "space-between",
                        textAlign: "left",
                        // marginTop: "0.5rem",
                      }}
                    >
                      <div
                        id={"invite-user"}
                        style={{
                          fontSize: "1.2rem",
                          // padding: "1rem",
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          marginLeft: "-0.5rem",
                          justifyContent: "flex-start",
                          border: bordersEnabled?  "solid 1px darkgray" : "none",
                        }}
                      >
                        <input
                          id={"invite-email-input"}
                          name={"invite-email-input"}
                          type={"text"}
                          // value={inviteEmail.current}
                          style={{
                            flexGrow: "3",
                            fontSize: "1.2rem",
                            paddingLeft: "1rem",
                            height:"2.4rem",
                            border: "none",
                            borderRadius: "4px",
                            backgroundColor: "#e9effe",
                            overflow: "hidden",
                            boxShadow: "1px 1px 2px white,  inset 1px 1px 2px rgba(0,0,0,0.25)",
                            margin: "0.5rem",
                          }}
                          onChange={(event) => {
                            console.log(event.target.value);
                            inviteEmail.current = event.target.value;

                                setSendButtonState(true);


                              // if (validateEmail()) {
                            //   setSendButtonState(true);
                            // }
                            // else {
                            //   setSendButtonState(false);
                            // }
                          }}
                          placeholder={"email address..."}

                        >
                        </input>

                        <div
                          id={"send-invite-button"}
                          className={sendButtonState === true? "send-invite-button" : "send-invite-button disabled"}
                          style={{
                            margin: "0.5rem",
                            height: "2.4rem",
                            minWidth: "8.2rem",
                            borderRadius: "4px",
                            flexGrow: "2",
                            color: "#f4f4f4",
                            fontSize: "1rem",
                            textAlign: "center",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                          onClick={() => {
                            if (sendButtonState !== true) {
                              return;
                            }
                            document.getElementById('invite-email-input').disabled = true;

                            sendInvitation(inviteEmail.current).then(() => {
                              document.getElementById('invite-email-input').value = '';
                            })
                          }}
                        >
                          {sendButtonState === "sending"?
                            <div className="lds-dual-ring"></div>
                            :
                            <b>CREATE INVITE</b>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      fontSize: "1.2rem",
                      textAlign: "center",
                      marginTop: "0.5rem",
                      minHeight: "3.6rem",
                      color: "#56a2cf"
                    }}>
                    <p>{invitationResponseMessage}</p>
                  </div>
                </div>

              {/*  <div*/}
              {/*    id={"invitation-stats-container"}*/}
              {/*    style={{*/}
              {/*      display: "flex",*/}
              {/*      flexDirection: "column",*/}
              {/*      justifyContent: "center",*/}
              {/*      alignItems: "center",*/}
              {/*      width: "40%",*/}
              {/*      // border: "solid 2px yellow",*/}
              {/*      textAlign: "center",*/}
              {/*      fontSize: "1.2rem",*/}
              {/*      padding: "0.5rem",*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <div*/}
              {/*      id={"seats-total"}*/}
              {/*      style={{*/}
              {/*        width: "85%",*/}
              {/*        display: "flex",*/}
              {/*        justifyContent: "space-between",*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <p>Seats total</p>*/}
              {/*      <p><strong>{totalSeats}</strong></p>*/}
              {/*    </div>*/}

              {/*    <div*/}
              {/*      id={"seats-available"}*/}
              {/*      style={{*/}
              {/*        width: "85%",*/}
              {/*        display: "flex",*/}
              {/*        justifyContent: "space-between",*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <p>Seats available</p>*/}
              {/*      <p><strong>{totalSeats - activations.length}</strong></p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              </div>




              <div
                id={"seats-used-container"}
                style={{
                  marginTop: "2rem",
                  boxShadow: "-2.5px -2.5px 4px white,  2px 2px 4px rgba(0,0,0,0.08)",
                  borderRadius: "6px",

                  padding: "2rem",
                }}
              >
                <h3>Active users: </h3>

                <div
                  id={"seat-info"}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    textAlign: "left",
                    marginTop: "0.5rem",
                  }}
                >

                  <div
                    id={"seat-name-header"}
                    style={{
                      fontSize: "1.2rem",
                      border: bordersEnabled? "solid 1px darkgray" : "none",
                      width: "20%",
                      flexGrow: "2",
                    }}
                  >
                    <p><strong>Name</strong></p>
                  </div>

                  <div
                    id={"seat-email-header"}
                    style={{
                      fontSize: "1.2rem",
                      width: "30%",
                      flexGrow: "3",
                      border: bordersEnabled? "solid 1px darkgray" : "none",
                      marginLeft: "1rem",
                    }}
                  >
                    <p><strong>Email</strong></p>
                  </div>

                    {subscriptions.map(subscription => {

                        return (
                            <div
                                id={"seat-use-header"}
                                style={{
                                    fontSize: "0.75rem",
                                    fontWeight: "700",
                                    width: "6.25%",
                                    flexWrap: "wrap",
                                    overflow: "hidden",
                                    flexGrow: "1",
                                    border: bordersEnabled? "solid 1px darkgray" : "none",
                                    marginLeft: "1rem",
                                    textAlign: "center",
                                }}
                            >
                                {subscription.productName.toString().replace("Stratbox", "")}
                            </div>

                        )
                    })}
                </div>

                {
                  users.map((seatInfo, i) => {
                      return (
                        <div
                          id={"seat-info"}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            textAlign: "left",
                            marginTop: "0.5rem",
                          }}
                        >

                          <div
                            id={"seat-name"}
                            style={{
                              fontSize: "1.2rem",
                              border: bordersEnabled? "solid 1px darkgray" : "none",
                              width: "20%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              flexGrow: "2",
                            }}
                          >
                            <p
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(seatInfo.fName + " " + seatInfo.lName);
                                setCopiedNotif({["name" + i.toString()]:<i>copied to clipboard</i>});

                                setTimeout(function() {
                                  setCopiedNotif("");
                                }, 750);
                              }}>{copiedNotif === "" || copiedNotif["name" + i.toString()] === undefined? seatInfo.fName + " " + seatInfo.lName : copiedNotif["name" + i.toString()]}</p>
                          </div>

                          <div
                            id={"seat-email"}
                            style={{
                              fontSize: "1.2rem",
                              border: bordersEnabled? "solid 1px darkgray" : "none",
                              width: "30%",
                              overflow: "clip",
                              marginLeft: "1rem",
                              flexGrow: "3",
                            }}
                          >
                            <p
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(seatInfo.email);
                                setCopiedNotif({["email" + i.toString()]:<i>copied to clipboard</i>});

                                setTimeout(function() {
                                  setCopiedNotif("");
                                }, 750);
                              }}>{copiedNotif === "" || copiedNotif["email" + i.toString()] === undefined? seatInfo.email : copiedNotif["email" + i.toString()]}
                            </p>
                          </div>

                            {subscriptions.map(subscription => {

                                return (
                                    <div
                                        id={"seat-in-use"}
                                        style={{
                                            width: "6.25%",
                                            height: "2rem",
                                            verticalAlign: "middle",
                                            marginLeft: "1rem",
                                            textAlign: "center",
                                            flexGrow: "1",
                                        }}
                                    >
                                        {/*{seatInfo.inUse &&*/}
                                        <img
                                            className={"seatInUse"}
                                            style={{
                                                filter: "drop-shadow(-0.25px -0.25px 0.25px hsl(118,10%,87%)) drop-shadow(0.5px 0.5px 0.5px rgba(0,0,0,0.15)",
                                                alignSelf: "center",
                                                height: "1.4rem",
                                                opacity: activations.filter(activation => (activation.email === seatInfo.email && activation.appSubId === subscription.id)).length > 0 ? "0.85" : "0.15",
                                            }}
                                            src={icon_check}
                                        />
                                    </div>

                                )
                            })}

                          {/*<div*/}
                          {/*  id={"revoke-seat-button"}*/}
                          {/*  className={revokeButtonState[i] === true || revokeButtonState === true? "revoke-seat-button" : "revoke-seat-button disabled"}*/}
                          {/*  style={{*/}
                          {/*    width: "15%",*/}
                          {/*    verticalAlign: "middle",*/}
                          {/*    height: "2rem",*/}
                          {/*    minWidth: "5.2rem",*/}
                          {/*    borderRadius: "4px",*/}
                          {/*    marginLeft: "1rem",*/}
                          {/*    color: "#f4f4f4",*/}
                          {/*    fontSize: "1rem",*/}
                          {/*    textAlign: "center",*/}
                          {/*    padding: "0.25rem",*/}
                          {/*    cursor: "pointer",*/}
                          {/*    flexGrow: "2",*/}
                          {/*    display: "flex",*/}
                          {/*    justifyContent: "center",*/}
                          {/*    alignItems: "center",*/}
                          {/*    // boxShadow: "-1px -1px 1.5px hsl(358,65%,92%),  1px 1px 1.5px rgba(0,0,0,0.35)",*/}
                          {/*  }}*/}
                          {/*  onClick={() => {*/}
                          {/*    if (Object.values(revokeButtonState).includes("sending")) {*/}
                          {/*      return;*/}
                          {/*    }*/}
                          {/*    setRevokeButtonState({[i]: "sending"});*/}
                          {/*    revokeUser(seatInfo, i);*/}
                          {/*  }}*/}
                          {/*>*/}
                          {/*  {revokeButtonState[i] === "sending"?*/}
                          {/*    <div className="lds-dual-ring"></div>*/}
                          {/*    :*/}
                          {/*    <b*/}
                          {/*      // id={"buttonText"}*/}
                          {/*      // className={"buttonText"}*/}
                          {/*      style={{*/}
                          {/*        // backgroundClip: "text",*/}
                          {/*        // webkitBackgroundClip: "text",*/}
                          {/*        // mozBackgroundClip: "text",*/}
                          {/*        // backgroundColor: "white",*/}
                          {/*        // color: "transparent"*/}
                          {/*      }}*/}
                          {/*    >REMOVE</b>*/}
                          {/*  }*/}
                          {/*</div>*/}
                        </div>
                      )
                    }
                  )
                }
                <div
                  style={{
                    fontSize: "1.2rem",
                    textAlign: "center",
                    marginTop: "1rem",
                    color: "#56a2cf",
                  }}>
                  <p>{revokeResponseMessage}</p>
                </div>
              </div>


              <div
                id={"pending-invites-container"}
                style={{
                  marginTop: "2rem",
                  boxShadow: "-2.5px -2.5px 4px white,  2px 2px 4px rgba(0,0,0,0.08)",
                  borderRadius: "6px",
                  padding: "2rem",
                }}
              >
                <h3>Pending invitations: </h3>

                {/*<div*/}
                {/*  id={"seat-info"}*/}
                {/*  style={{*/}
                {/*    display: "flex",*/}
                {/*    flexDirection: "row",*/}
                {/*    justifyContent: "space-between",*/}
                {/*    textAlign: "left",*/}
                {/*    marginTop: "0.5rem",*/}
                {/*  }}*/}
                {/*>*/}

                  {/*<div*/}
                  {/*  id={"invite-email-header"}*/}
                  {/*  style={{*/}
                  {/*    fontSize: "1.2rem",*/}
                  {/*    width: "85%",*/}
                  {/*    flexGrow: "3",*/}
                  {/*    border: bordersEnabled? "solid 1px darkgray" : "none",*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <p><strong>Email</strong></p>*/}
                  {/*</div>*/}

                  {/*<div*/}
                  {/*  id={"revoke-invite-header"}*/}
                  {/*  style={{*/}
                  {/*    fontSize: "1.2rem",*/}
                  {/*    flexGrow: "2",*/}
                  {/*    width: "15%",*/}
                  {/*    minWidth: "6.2rem",*/}
                  {/*    border: bordersEnabled? "solid 1px darkgray" : "none",*/}
                  {/*    marginLeft: "1rem",*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <p><strong></strong></p>*/}
                  {/*</div>*/}
                {/*</div>*/}

                <div
                  id={"seat-info"}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    gap: "0.75rem",
                  }}
                >
                {
                  invitations.map((invitation, i) => {

                      return (
                        <div
                          id={"seat-info"}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignContent: "center",
                            alignSelf: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            paddingTop: "0.25rem",
                            paddingBottom: "0.25rem",
                            backgroundColor: "#e9effe",
                            cursor: "pointer",
                            fontSize: "1.2rem",
                          }}
                          onClick={() => {

                            console.log(Array.from(invitation.email).splice(0,Math.ceil(invitation.email.length / 2)))
                            navigator.clipboard.writeText(invitation.email);
                            setCopiedNotif({ ["email2" + i.toString()] :
                                <i> { Array.from(invitation.email).splice(0,Math.ceil(invitation.email.length / 2)).map((char, i) => { if(Number(i) > 9 ) {return("⠀")}}) }
                                  copied to clipboard
                                  { Array.from(invitation.email).splice(0,Math.ceil(invitation.email.length / 2)).map((char, i) => { if(Number(i) > 9 ) {return("⠀")}}) }
                                </i>
                            });

                            setTimeout(function() {
                              setCopiedNotif("");
                            }, 750);
                          }}
                        >
                          {/*<Icon*/}
                          {/*  link*/}
                          {/*  color={"blue"}*/}
                          {/*  size={"small"}*/}
                          {/*  name={"copy outline"}*/}
                          {/*  style={{alignSelf: "center"}}*/}
                          {/*/>*/}

                            <div
                                style={{alignSelf: "center"}}
                            />

                          <p>{copiedNotif === "" || copiedNotif["email2" + i.toString()] === undefined? invitation.email : copiedNotif["email2" + i.toString()]}</p>


                          {/*<div*/}
                          {/*  id={"revoke-invite-button"}*/}
                          {/*  className={cancelButtonState[i] === true || cancelButtonState === true? "revoke-seat-button" : "revoke-seat-button disabled"}*/}
                          {/*  style={{*/}
                          {/*    width: "15%",*/}
                          {/*    verticalAlign: "middle",*/}
                          {/*    height: "2rem",*/}
                          {/*    minWidth: "5.2rem",*/}
                          {/*    borderRadius: "4px",*/}
                          {/*    marginLeft: "1rem",*/}
                          {/*    color: "#f4f4f4",*/}
                          {/*    fontSize: "1rem",*/}
                          {/*    textAlign: "center",*/}
                          {/*    padding: "0.25rem",*/}
                          {/*    cursor: "pointer",*/}
                          {/*    flexGrow: "2",*/}
                          {/*    display: "flex",*/}
                          {/*    justifyContent: "center",*/}
                          {/*    alignItems: "center",*/}
                          {/*    boxShadow: "-1.5px -1.5px 2px white,  1px 1px 2px rgba(0,0,0,0.25)",*/}

                          {/*  }}*/}

                          {/*  onClick={() => {*/}
                          {/*    if (Object.values(cancelButtonState).includes("sending")) {*/}
                          {/*      return;*/}
                          {/*    }*/}
                          {/*    setCancelButtonState({[i]: "sending"});*/}
                          {/*    cancelInvitation(invitation, i);*/}
                          {/*  }}*/}
                          {/*>*/}
                          {/*  {cancelButtonState[i] === "sending"?*/}
                          {/*    <div className="lds-dual-ring"></div>*/}
                          {/*    :*/}
                          {/*    <b>CANCEL</b>*/}
                          {/*  }*/}
                          {/*</div>*/}
                        </div>
                      )
                    }
                  )
                }
                </div>
                <div
                  style={{
                    fontSize: "1.2rem",
                    textAlign: "center",
                    marginTop: "1rem",
                    color: "#56a2cf",
                  }}>
                  <p>{cancelResponseMessage}</p>
                </div>
              </div>
            </div>

          </div>



          {/* FOOTER */}
          <footer
            style={{
              maxHeight: "5vh",
            }}
          >
            <div
              className="footer-logo"
              style={{
                alignSelf: "center",
              }}
            >
              <img
                src={irLogo}
                draggable="false"
              >
              </img>
            </div>
          </footer>

        </div>

    </div>
  );
}

export default Administration;
