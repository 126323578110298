import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { forgotPassword } from "../shared/password";
import ReCAPTCHA from "react-google-recaptcha";

function ForgotPassword (){
    const captchaRef:any = React.createRef();

    const [formData, setFormData] = React.useState(
        {
            email:"",
            emailValid:false,
            emailFeedback:"",
            formValidated:false,
            isResetting:false,
            resetFailed:false,
            resetFeedback:<></>,
            resetFetched:false,
            captchaCompleted:false
        }
    )

        useEffect(()=>{
            document.title = 'Forgot Password | Stratbox';
        });

        function validateForm(callback: Function){
            var emailFeedback = "Please enter your email.";
            var emailValid = false;
            if( formData.email.length > 0){
                emailValid = formData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
                if(!emailValid) emailFeedback = "Please enter a valid email.";
            }
    
            setFormData(prevFormData=>{
                return {
                    ...prevFormData,
                    formValidated: true,
                    emailValid: emailValid,
                    emailFeedback: emailFeedback
                }
            });
    
            callback(emailValid && formData.captchaCompleted);
        }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>){
        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    function handleRecaptchaChange(value:any){
        console.log("Captcha value:", value);
        if(value){
            setFormData(prevFormData=>{
                return {
                    ...prevFormData,
                    captchaCompleted:true
                }
            })
        }
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();
        if(formData.isResetting)return;

        validateForm(async (success: boolean)=>{
            if(success){
                setFormData(prevFormData=>{
                    return {
                        ...prevFormData,
                        isResetting: true,
                        resetFailed:false,
                    }
                });
                console.log("requesting password reset link");
                //api call to request password reset link
                const captchaToken = captchaRef.current.getValue();
                captchaRef.current.reset();
                const {error,payload} = await forgotPassword(formData.email, captchaToken);

                if(error){
                    setFormData(prevFormData=>{
                        return {
                            ...prevFormData,
                            isResetting: false,
                            resetFailed: true,
                            resetFetched:true,
                            resetFeedback: <>{error}</>
                        }
                    });
                    console.log(error);
                }else{
                    setFormData(prevFormData=>{
                        return {
                            ...prevFormData,
                            isResetting: false,
                            resetFailed: false,
                            resetFetched:true,
                            resetFeedback: <>We've sent you an email with a link <u><b>valid for 24 hours</b></u> to reset your password.<br></br>(Please check your spam folder)</>
                        }
                    });
                    console.log(payload);
                }
            }
        })
    }

function renderForgotPasswordForm(){
    return(
        <>
        <div className="login-title">Forgot your password?</div>
                    <div className="login-subtitle">We'll email you a recovery link</div>
                    <form id="form-login" onSubmit={handleSubmit} noValidate>
                            <div id="email-field">
                                <input 
                                    type="email" 
                                    placeholder="Enter email" 
                                    id="email"
                                    className={`form-control ${(formData.formValidated && !formData.emailValid)?"is-invalid":""} text-09`}
                                    name="email"
                                    onChange={handleChange}
                                    value={formData.email}
                                />
                                <div className="invalid-feedback">
                                    {formData.emailFeedback}
                                </div>
                                <div className=" mb-3"></div>
                            </div>

                            <div>
                            <ReCAPTCHA 
                                sitekey={process.env.REACT_APP_STBX_RECAPTCHA_SITE_KEY || ""}
                                ref={captchaRef}
                                onChange={handleRecaptchaChange}
                                className="recaptcha"
                            />
                            <div className=" mb-3"></div>
                            <button type="submit" id="login-submit" className="btn btn-stbx-primary form-control">
                                    {formData.isResetting
                                        ? <div className="spinner-border spinner-border-sm text-light" role="status">
                                        </div>
                                        : <span>Reset Password</span>
                                    }
                            </button>
                            </div>
                    </form>
        </>
    )
}

    return(
        <div className="flex-container">
            <div>
                <Header/>
                <section className="sc-login p-4">
                    {formData.resetFetched ? <div className="verify-title">{formData.resetFeedback}</div> : renderForgotPasswordForm()}
                </section>
            </div>
            <Footer/>
        </div>
    )
}

export default ForgotPassword;