import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Join from './pages/SignUp/Join';
import Verify from './pages/SignUp/Verify';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Dashboard from "./pages/Dashboard/Dashboard";
import Administration from "./pages/Administration/Administration";
import {  getSubscriptions, getUser, getBuildList } from "./shared/authentication";
import { createRoot } from "react-dom/client";

interface UserDetails {
    first_name: string;
    last_name: string;
    organisation_id: string;
    organisation_name: string;
    role: string;
}

const enterpriseOrgs = [
    ""
]

const App = () => {
    const [loading, setLoading] = useState(true);
    const [checkAuthResult, setCheckAuthResult] = useState<boolean>(false);
    const [isAdminUser, setIsAdminUser] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState<any | null>();
    const [subscriptionDetails, setSubscriptionDetails] = useState<any | null> ();
    const [buildList, setBuildList] = useState<any | null>();
    const [isEnterpriseUser, setIsEnterpriseUser] = useState(false);

    useEffect(() => {
        getUser().then(({error, payload}) => {

            console.log("checkAuthResult:");
            console.log(error);
            console.log(payload);

            if (error !== null) {
                setCheckAuthResult(false);
                setLoading(false);
            }
            else {
                setUserDetails(payload.payload);

                if (payload.role === 'admin') {
                    setIsAdminUser(true);
                }
                else {
                    setIsAdminUser(false);
                }

                if (enterpriseOrgs.includes(payload.organisation_id)) {
                    setIsEnterpriseUser(true);
                }

                getSubscriptions().then(({error, payload}) => {

                    console.log("getSubscriptionsResult:");

                    console.log(`Error:`);
                    console.log(error);
                    console.log(`Payload:`);
                    console.log(payload);

                    if (error === null) {
                        console.log("No error, setting user subs & authResult = true");
                        setSubscriptionDetails(payload.payload);
                        setCheckAuthResult(true);

                        getBuildList().then(({error, payload})=>{
                            console.log("getBuildListResult:");
                            console.log(`Error:`);
                            console.log(error);
                            console.log(`Payload:`);
                            console.log(payload);

                            if (error === null) {
                                //@ts-ignore
                                setBuildList(payload.payload);
                                console.log("Loading off");
                                setLoading(false);
                            }else{
                                console.log("Loading off");
                                setLoading(false);
                            }
                        });
                    }
                    else {
                        console.log("Loading off");
                        setLoading(false);
                    }
                });
            }
        });
    }, []);

    if (loading) {
        // return null; // Or replace with a loading spinner

        return (
          <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                }}
          >
              <div className={"spinner"}></div>
          </div>
        )
    }

    else {
        return (
          <BrowserRouter>
              <Routes>

                  <Route path="/"
                     element={checkAuthResult ?
                        <Dashboard
                            userDetails={userDetails}
                            subscriptionDetails={subscriptionDetails}
                            buildList={buildList}
                        />
                        :
                        <Login/>
                    }
                  />

                  <Route
                      path="login"
                      element={checkAuthResult ?
                        <Dashboard
                            userDetails={userDetails}
                            subscriptionDetails={subscriptionDetails}
                            buildList={buildList}
                        />
                        :
                        <Login/>
                      }
                  />

                  <Route
                      path="dashboard"
                      element={checkAuthResult ?
                          <Dashboard
                            userDetails={userDetails}
                            subscriptionDetails={subscriptionDetails}
                            buildList={buildList}
                          />
                        :
                          <Navigate to="/login"/>
                        }
                  />

                  <Route
                      path="administration"
                      element={isAdminUser ?
                          <Administration
                              organisationName={userDetails !== null ?
                                  userDetails.organisation_name
                                  : "NA"
                          }/>
                        :
                          <Navigate to="/dashboard"/>}
                  />

                  <Route path="logout" element={<Logout/>}/>
                  <Route path="forgot-password" element={<ForgotPassword/>}/>
                  <Route path="reset-password" element={<ResetPassword/>}/>
                  <Route path="/signup/join" element={<Join/>}/>
                  <Route path="/signup/verify" element={<Verify/>}/>
              </Routes>
          </BrowserRouter>
        );
    }
};

const root = document.getElementById('root');
if (root !== null) {
    createRoot(root).render(<App />);
}