// import ReactTooltip from "react-tooltip";
import stbxLogo from '../../img/stratbox_logo.webp';
import irLogo from '../../img/ir_logo.webp';
import "../../administration.css";
import React, {useEffect, useState} from "react";
import Administration from "../Administration/Administration";
import Cookies from "js-cookie";
import {logout} from "../../shared/authentication";
import {Navigate, useSearchParams} from "react-router-dom";

import strings from "../../strings.json";
import {AvatarMenu} from "../../components/avatarMenu";


function Dashboard(props) {

    useEffect(()=>{
        document.title = 'Dashboard | Stratbox';
    });


    const defaultBuildList={
        "Stratbox Connect": {
            "Win":{
                "fileName":"",
                "version":""
            },
            "Mac":{
                "fileName":"",
                "version":""
            }
        },
        "Stratbox Connect VR": {
            "Win":{
                "fileName":"",
                "version":""
            },
            "Focus":{
                "fileName":"",
                "version":""
            },
            "Quest":{
                "fileName":"",
                "version":""
            }
        },
        "Stratbox Analytics": {
            "Win":{
                "fileName":"",
                "version":""
            }
        }
    };

    const parseBuildList = (buildList)=>{
        if(!buildList) return defaultBuildList;
        let buildDictionary = defaultBuildList;
        buildList.forEach(item => {
            console.log("item: ",item)
            let buildName = item.Key;
            let itemWithoutExtension = buildName.split('.').slice(0, -1).join('.');
            let buildDetails = itemWithoutExtension.split('_');
            if(buildDetails.length == 3){
                if(!buildDictionary[buildDetails[0]])buildDictionary[buildDetails[0]]={};
                buildDictionary[buildDetails[0]][buildDetails[1]] = 
                {
                    fileName: buildName,
                    version: buildDetails[2]
                };
            }
        });   
        console.log("buildDictionary: ",buildDictionary) 
        return buildDictionary;
    }

    const enterpriseOrganisations = [
        "o-04028992", // Shell
        "o-86865022", // Hess
        "o-34727109", // YPF
        "o-61345819", // Imaged Reality
        "o-41406782" // test org
    ];

    const [avatarMenuOpen, setAvatarMenuOpen] = useState(false);
    const isEnterprise = enterpriseOrganisations.includes(props.userDetails.organisation_id);

    console.log(`IsEnterprise: ${isEnterprise}`);
    console.log("props: ",props);

    const builds = parseBuildList(props.buildList);
    console.log("builds: ",builds);

    const appDescriptions = {
        "Stratbox Connect": "Real time collaboration with 3D photogrammetry models",
        "Stratbox Connect VR": "The metaverse for Geoscience",
        "Stratbox Analytics": "Quantitative outcrop interpretation",
        "Stratbox Core Explorer": "The Digital twin of well core data"
    }

    const appLogos = {
        "Stratbox Analytics": "analytics_HOR_MED.png",
        "Stratbox Connect": "connect_HOR_MED.png",
        "Stratbox Connect VR": "connect-vr_HOR_MED.png",
        "Stratbox Core Explorer": "core-explorer_HOR_MED.png",
    }

    const appURIs = {
        "Stratbox Analytics": 'stbxanalytics://',
        "Stratbox Connect": 'stbxconnect://',
        "Stratbox Connect VR": 'stbxvr://',
        "Stratbox Core Explorer": 'https://core-explorer.stratbox.imagedreality.com',
    }

  const appStoreURIs = {
    "Stratbox Connect": "https://store.imagedreality.com/Stratbox%20Connect",
    "Stratbox Connect VR": "https://store.imagedreality.com/Stratbox%20Connect%20VR",
    "Stratbox Analytics": "https://store.imagedreality.com/Stratbox%20Analytics",
    "Stratbox Core Explorer": "https://store.imagedreality.com/Stratbox%20Core%20Explorer"
  }

    const appDownloadURIs = {
        "Stratbox Analytics": 'https://imagedreality.com',
        "Stratbox Connect": 'https://imagedreality.com',
        "Stratbox Connect VR" : 'https://imagedreality.com',
        "Stratbox Core Explorer": 'https://imagedreality.com'
    }

    const appInfoURIs = {
        "Stratbox Analytics": 'https://www.imagedreality.com/stratbox-analytics/',
        "Stratbox Connect": 'https://www.imagedreality.com/core-explorer/',
        "Stratbox Connect VR" : 'https://www.imagedreality.com/stratbox-connect/',
        "Stratbox Core Explorer": 'https://www.imagedreality.com/stratbox-connect/#StratboxVR_01'
    }

    const storeURL = "https://store.imagedreality.com"

    const [enableAdmin, setEnableAdmin] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    console.log(searchParams.get('enable-admin') || "not administration...");

    if (searchParams.get('enable-admin') === "true") {
        // setEnableAdmin(true);
        return <Administration organisationName={props.userDetails !== null ? props.userDetails.organisation_name : "NA"} />
    }

    if (enableAdmin) {

        return <Administration organisationName={props.userDetails !== null ? props.userDetails.organisation_name : "NA"} />
    }


    else {

        return (
            <div
                className="Management"
                style={{
                    height: "100vh",
                }}
            >

                <title>Stratbox Dashboard</title>

                {/* HEADER */}
                <div
                    style={{
                        height: "3rem",
                        width: "100%",
                        display: "flex",
                        zIndex: "10",
                        padding: "0.625rem 0.625rem 0.625rem 0.625rem",
                        top: 0,
                        left: 0,
                        backgroundColor: strings.colors.backgroundPrimary,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexGrow: "1",
                            // width: "3.75rem",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                        }}
                    >
                        <img
                            className="header-logo-img"
                            src={stbxLogo}
                            height={"100%"}
                            draggable="false"
                        >
                        </img>


                    </div>


                    {/* AVATAR / MENU: */}
                    <div
                        style={{
                            color: "#f4f4f4",
                            paddingRight: "0.375rem",
                            display: "flex",
                            height: "100%",
                            alignItems: "center",
                            justifySelf: "flex-end",
                            justifyContent: "center",
                            cursor: "pointer",
                            // border: "solid 1px blue",
                        }}
                        onClick={() => setAvatarMenuOpen(!avatarMenuOpen)}
                    >

                        <div
                            className={`material-icons`}
                            style={{
                                color: strings.colors.textPrimary,
                                fontSize: strings.sizes.iconPrimary,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "center",
                                cursor: "pointer",
                            }}
                        >
                            account_circle
                        </div>
                        <div
                            style={{
                                color: strings.colors.textPrimary,
                                fontSize: strings.sizes.textPrimary,
                                fontWeight: strings.weights.medium,
                                marginLeft: "0.5rem",
                            }}
                        >
                            {props.userDetails.first_name}
                        </div>

                        <AvatarMenu
                            userDetails={props.userDetails}
                            isOpen={avatarMenuOpen}
                            setAvatarMenuOpen={setAvatarMenuOpen}
                            setEnableAdmin={setEnableAdmin}
                        />
                    </div>

                </div>

                <div
                    id={'page-container'}
                    style={{
                        height: "calc(100% - 3rem)",
                        flexGrow: "1",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        backgroundColor: "#fafbfc",
                        overflow: "scroll",
                    }}
                >

                    {/*PRODUCTS LABEL*/}
                    <div
                        style={{
                            fontSize: strings.sizes.textPrimary,
                            color: strings.colors.textSecondary,
                            fontFamily: "Inter",
                            fontWeight: 600,
                            justifyContent: "center",
                            width: "100%",
                            display: "flex",
                            marginTop: "2rem",
                        }}
                    >
                        My Products
                    </div>

                    {/*SUBSCRIPTIONS*/}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        }}
                    >

                        {Object.entries(appDescriptions).map(([appName, appDescription], index) => {
                            return (
                                <div
                                    key={index}
                                    id={`subscription-box-${index}`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "22rem",
                                        height: "12rem",
                                        flexDirection: "column",
                                        backgroundColor: strings.colors.backgroundPrimary,
                                        margin: "1rem",
                                        borderRadius: "0.25rem",
                                        boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.2)",
                                    }}
                                >

                                    {/*Logo*/}
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            src={appLogos[appName]}
                                            height={appName === "Stratbox Connect" ? "50rem" : "46rem"}
                                        />
                                    </div>

                                    {/*Description*/}
                                    <div
                                        style={{
                                            color: strings.colors.textSecondary,
                                            fontSize: strings.sizes.textSecondary,
                                            lineHeight: strings.sizes.textSecondary,
                                            fontFamily: "Inter",
                                            textAlign: "center",
                                            width: "90%",
                                            fontWeight: 500,
                                            marginTop: "0.125rem",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {appDescription}
                                    </div>

                                    {/* Buttons */}


                                        {/* HAS A SUBSCRIPTION */}
                                        {props.subscriptionDetails.some(subscription => subscription.app === appName)?

                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-around",
                                                    width: "100%",
                                                    alignItems: "center",
                                                    marginTop: "0.75rem",
                                                }}
                                            >

                                        {/* SUBCRIPTION EXPIRED */}
                                            {props.subscriptionDetails.some(subscription =>
                                                  subscription.app === appName
                                                  && new Date(subscription.expires) < new Date()
                                                )?

                                                    <div
                                                      style={{
                                                        width: "9rem",
                                                        height: "1.75rem",
                                                        color: strings.colors.actionNegative,
                                                        fontSize: strings.sizes.textSecondary,
                                                        fontWeight: strings.weights.bold,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        borderRadius: "0.125rem",
                                                        cursor: "pointer",
                                                        marginBottom: "0.75rem",
                                                      }}
                                                    >
                                                      Subscription expired
                                                    </div>
                                                    :

                                        // SUBSCRIPTION VALID
                                                    <div
                                                        style={{
                                                            width: "7rem",
                                                            height: "1.75rem",
                                                            backgroundColor: strings.colors.actionNeutral,
                                                            color: strings.colors.backgroundPrimary,
                                                            fontSize: strings.sizes.textSecondary,
                                                            fontWeight: strings.weights.medium,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            borderRadius: "0.125rem",
                                                            cursor: "pointer",
                                                            marginBottom: "0.75rem",
                                                        }}
                                                        onClick={() =>
                                                            window.open(appURIs[appName], '_blank')
                                                        }
                                                    >
                                                        Open
                                                    </div>
                                            }



                                            {/*CORE EXPLORER:*/}
                                            {appName === "Stratbox Core Explorer" &&
                                                <div></div>
                                            }


                                            {/*CONNECT:*/}
                                            {appName == "Stratbox Connect" &&
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        justifyContent: "space-evenly",
                                                    }}
                                                >
                                                    <div
                                                        className='download-btn'
                                                        style={{
                                                            backgroundColor: strings.colors.backgroundTertiary,
                                                            color: strings.colors.textSecondary,
                                                            fontSize: strings.sizes.textTertiary,
                                                            fontWeight: strings.weights.medium
                                                        }}
                                                        onClick={async () => {
                                                            const response = await fetch(process.env.REACT_APP_STBX_API_URL + "/user" + "/get-product-url", {
                                                                method: 'POST',
                                                                headers: {'Content-Type': 'application/json'},
                                                                credentials: 'include',
                                                                body: JSON.stringify({product: builds["Stratbox Connect"]["Win"].fileName})
                                                            });

                                                            if (response.ok) {
                                                                const result = await response.json();
                                                                console.log(result);

                                                                window.open(result.payload, '_blank')
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: strings.sizes.textTertiary,
                                                                fontWeight: strings.weights.normal,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >
                                                            Download for
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: strings.sizes.textTertiary,
                                                                fontWeight: strings.weights.medium,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >
                                                            Windows
                                                        </div>
                                                        <span style={{
                                                                fontSize: strings.sizes.textQuaternary,
                                                                fontWeight: strings.weights.medium,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >{builds["Stratbox Connect"]["Win"].version}</span>
                                                    </div>

                                                    <div
                                                        className='download-btn'
                                                        style={{
                                                            backgroundColor: strings.colors.backgroundTertiary,
                                                            color: strings.colors.textSecondary,
                                                            fontSize: strings.sizes.textTertiary,
                                                            fontWeight: strings.weights.medium
                                                        }}
                                                        onClick={async () => {
                                                            const response = await fetch(process.env.REACT_APP_STBX_API_URL + "/user" + "/get-product-url", {
                                                                method: 'POST',
                                                                headers: {'Content-Type': 'application/json'},
                                                                credentials: 'include',
                                                                body: JSON.stringify({product: builds["Stratbox Connect"]["Mac"].fileName})
                                                            });

                                                            if (response.ok) {
                                                                const result = await response.json();
                                                                console.log(result);

                                                                window.open(result.payload, '_blank')
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: strings.sizes.textTertiary,
                                                                fontWeight: strings.weights.normal,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >
                                                            Download for
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: strings.sizes.textTertiary,
                                                                fontWeight: strings.weights.medium,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >
                                                            Mac
                                                        </div>
                                                        <span style={{
                                                                fontSize: strings.sizes.textQuaternary,
                                                                fontWeight: strings.weights.medium,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >{builds["Stratbox Connect"]["Mac"].version}</span>
                                                    </div>

                                                </div>
                                            }

                                            {/*CONNECT VR:*/}
                                            {appName == "Stratbox Connect VR" &&
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        justifyContent: "space-evenly",
                                                    }}
                                                >
                                                    <div
                                                        className='download-btn'
                                                        style={{
                                                            backgroundColor: strings.colors.backgroundTertiary,
                                                            color: strings.colors.textSecondary,
                                                            fontSize: strings.sizes.textTertiary,
                                                            fontWeight: strings.weights.medium
                                                        }}
                                                        onClick={async () => {
                                                            const response = await fetch(process.env.REACT_APP_STBX_API_URL + "/user" + "/get-product-url", {
                                                                method: 'POST',
                                                                headers: {'Content-Type': 'application/json'},
                                                                credentials: 'include',
                                                                body: JSON.stringify({product: builds["Stratbox Connect VR"]["Win"].fileName})
                                                            });

                                                            if (response.ok) {
                                                                const result = await response.json();
                                                                console.log(result);

                                                                window.open(result.payload, '_blank')
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: strings.sizes.textTertiary,
                                                                fontWeight: strings.weights.normal,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >
                                                            Download for
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: strings.sizes.textTertiary,
                                                                fontWeight: strings.weights.medium,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >
                                                            PC-VR
                                                        </div>
                                                        <span style={{
                                                                fontSize: strings.sizes.textQuaternary,
                                                                fontWeight: strings.weights.medium,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >{builds["Stratbox Connect VR"]["Win"].version}</span>
                                                    </div>

                                                    <div
                                                        className='download-btn'
                                                        style={{
                                                            backgroundColor: strings.colors.backgroundTertiary,
                                                            color: strings.colors.textSecondary,
                                                            fontSize: strings.sizes.textTertiary,
                                                            fontWeight: strings.weights.medium
                                                        }}
                                                        onClick={async () => {
                                                            const response = await fetch(process.env.REACT_APP_STBX_API_URL + "/user" + "/get-product-url", {
                                                                method: 'POST',
                                                                headers: {'Content-Type': 'application/json'},
                                                                credentials: 'include',
                                                                body: JSON.stringify({product: builds["Stratbox Connect VR"]["Focus"].fileName})
                                                            });

                                                            if (response.ok) {
                                                                const result = await response.json();
                                                                console.log(result);

                                                                window.open(result.payload, '_blank')
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: strings.sizes.textTertiary,
                                                                fontWeight: strings.weights.normal,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >
                                                            Download for
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: strings.sizes.textTertiary,
                                                                fontWeight: strings.weights.medium,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >
                                                            HTC Focus 3
                                                        </div>
                                                        <span style={{
                                                                fontSize: strings.sizes.textQuaternary,
                                                                fontWeight: strings.weights.medium,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >{builds["Stratbox Connect VR"]["Focus"].version}</span>
                                                    </div>
                                                    <div
                                                        className='download-btn'
                                                        style={{
                                                            backgroundColor: strings.colors.backgroundTertiary,
                                                            color: strings.colors.textSecondary,
                                                            fontSize: strings.sizes.textTertiary,
                                                            fontWeight: strings.weights.medium
                                                        }}
                                                        onClick={async () => {
                                                            const response = await fetch(process.env.REACT_APP_STBX_API_URL + "/user" + "/get-product-url", {
                                                                method: 'POST',
                                                                headers: {'Content-Type': 'application/json'},
                                                                credentials: 'include',
                                                                body: JSON.stringify({product: builds["Stratbox Connect VR"]["Quest"].fileName})
                                                            });

                                                            if (response.ok) {
                                                                const result = await response.json();
                                                                console.log(result);

                                                                window.open(result.payload, '_blank')
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: strings.sizes.textTertiary,
                                                                fontWeight: strings.weights.normal,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >
                                                            Download for
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: strings.sizes.textTertiary,
                                                                fontWeight: strings.weights.medium,
                                                                lineHeight: strings.sizes.textTertiary
                                                            }}
                                                        >
                                                            Oculus Quest
                                                        </div>
                                                        <span style={{
                                                                fontSize: strings.sizes.textQuaternary,
                                                                fontWeight: strings.weights.medium,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >{builds["Stratbox Connect VR"]["Quest"].version}</span>
                                                    </div>
                                                </div>
                                            }

                                            {/*ANALYTICS:*/}
                                            {appName == "Stratbox Analytics" &&
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        justifyContent: "space-evenly",
                                                    }}
                                                >
                                                    <div
                                                        className='download-btn'
                                                        style={{
                                                            backgroundColor: strings.colors.backgroundTertiary,
                                                            color: strings.colors.textSecondary,
                                                            fontSize: strings.sizes.textTertiary,
                                                            lineHeight: strings.sizes.textTertiary,
                                                            fontWeight: strings.weights.medium
                                                        }}
                                                        onClick={async () => {
                                                            const response = await fetch(process.env.REACT_APP_STBX_API_URL + "/user" + "/get-product-url", {
                                                                method: 'POST',
                                                                headers: {'Content-Type': 'application/json'},
                                                                credentials: 'include',
                                                                body: JSON.stringify({product: builds["Stratbox Analytics"]["Win"].fileName})
                                                            });

                                                            if (response.ok) {
                                                                const result = await response.json();
                                                                console.log(result);

                                                                window.open(result.payload, '_blank')
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: strings.sizes.textTertiary,
                                                                fontWeight: strings.weights.normal,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >
                                                            Download for
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: strings.sizes.textTertiary,
                                                                fontWeight: strings.weights.medium,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >
                                                            Windows
                                                        </div>
                                                        <span style={{
                                                                fontSize: strings.sizes.textQuaternary,
                                                                fontWeight: strings.weights.medium,
                                                                lineHeight: strings.sizes.textTertiary,
                                                            }}
                                                        >{builds["Stratbox Analytics"]["Win"].version}</span>
                                                    </div>

                                                </div>
                                            }


                                        </div>



                                        :

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                                marginTop: "1rem",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "7rem",
                                                    height: "1.75rem",
                                                    backgroundColor: strings.colors.actionPositive,
                                                    color: strings.colors.backgroundPrimary,
                                                    fontSize: strings.sizes.textSecondary,
                                                    fontWeight: strings.weights.medium,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    borderRadius: "0.125rem",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {

                                                  window.location.href = `${appStoreURIs[appName]}`

                                                  // TODO: This logic was to prevent existing enterprise users from seeing normal prices. Not sure if we still want/need this logic:
                                                  //   if (isEnterprise) {
                                                  //       window.open(storeURL + "/plans?customer_type=enterprise", '_blank');
                                                  //   }
                                                  //   else {
                                                  //       window.open(storeURL + "/plans", '_blank');
                                                  //   }
                                                }}
                                            >
                                                Purchase
                                            </div>

                                        </div>
                                    }


                                    {props.subscriptionDetails.some(subscription => subscription.app === appName)?

                                        !isEnterprise && props.userDetails.role === "admin"?
                                            <a
                                                style={{
                                                    fontSize: strings.sizes.textSecondary,
                                                    fontWeight: strings.weights.medium,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginTop: "0.5rem",
                                                }}
                                                href={storeURL + "/account"}
                                                target={"_blank"}
                                            >
                                                Manage subscription
                                            </a>
                                        :
                                            <div></div>

                                        :

                                        <a
                                            style={{
                                                fontSize: strings.sizes.textSecondary,
                                                fontWeight: strings.weights.medium,
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: "1rem",
                                            }}
                                            href={appInfoURIs[appName]}
                                            target={"_blank"}
                                        >
                                            Learn more
                                        </a>
                                    }

                                </div>
                            )
                        })}

                    </div>


                    {/* FOOTER */}
                    <footer
                        style={{
                            // maxHeight: "5vh",
                            display: "flex",
                            marginBottom: "2rem",
                            flexGrow: "1",
                            height: "100%",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            borderTop: "none",
                        }}
                    >
                        <div
                            style={{
                                height: "1px",
                                width: "100%",
                                backgroundColor: "rgb(223, 225, 230)",
                            }}
                        >

                        </div>
                        <div
                            className="footer-logo"
                            style={{
                                alignSelf: "center",

                            }}
                        >
                            <img
                                src={irLogo}
                                draggable="false"
                            >
                            </img>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}

export default Dashboard;
