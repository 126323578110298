import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { resetPassword } from "../shared/password";
import { validatePassword } from "../shared/utils";

function ResetPassword (){

    const [searchParams, setSearchParams] = useSearchParams ();

    const [formData, setFormData] = React.useState(
        {
            password:"",
            passwordValid:false,
            passwordFeedback:"",
            confirmedPassword:"",
            confirmedPasswordValid:false,
            confirmedPasswordFeedback:"Please confirm your password.",
            formValidated:false,
            isResetting:false,
            resetFetched:false,
            resetFailed:false,
            resetFeedback:"",
            passwordVisible:false
        }
    )

        useEffect(()=>{
            document.title = 'Reset Password | Stratbox';
        });

        function validateForm(callback: Function){
            var passwordFeedback = "Please enter your new password.";
            var passwordValid = false;
            var confirmedPasswordValid = false;
            var confirmedPasswordFeedback = "Please confirm your password."

            if(formData.password.length > 0){
                passwordValid = validatePassword(formData.password); 
                if(!passwordValid) passwordFeedback = "You password must contain at least 8 characters (one digit, one upper case, one lower case and one special character, no spaces).";
            }
    
            if(formData.confirmedPassword.length > 0){
                confirmedPasswordValid = (formData.confirmedPassword === formData.password);
                if(!confirmedPasswordValid) confirmedPasswordFeedback = "Passwords must match";
            }

            setFormData(prevFormData=>{
                return {
                    ...prevFormData,
                    formValidated: true,
                    passwordValid: passwordValid,
                    passwordFeedback: passwordFeedback,
                    confirmedPasswordValid: confirmedPasswordValid,
                    confirmedPasswordFeedback: confirmedPasswordFeedback
                }
            });
    
            callback(passwordValid && confirmedPasswordValid);
        }

        function showPassword(){
            setFormData(prevFormData=>{
                return {
                    ...prevFormData,
                    passwordVisible: true,
                }
            });
        }
    
        function hidePassword(){
            setFormData(prevFormData=>{
                return {
                    ...prevFormData,
                    passwordVisible: false,
                }
            });
        }
        
    function handleChange(event: React.ChangeEvent<HTMLInputElement>){
        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>){

        event.preventDefault();
        if(formData.isResetting)return;
        validateForm(async (success: boolean)=>{
            if(success){
                setFormData(prevFormData=>{
                    return {
                        ...prevFormData,
                        isResetting: true,
                        resetFailed:false,
                    }
                });

                //api call to reset password
                var email = searchParams.get('email') || "";
                var verification_code = searchParams.get('verification_code') || "";
        
                if(email && verification_code){
                    const {error,payload} = await resetPassword(email, verification_code, formData.password);
                    if(error){
                        var message = "An error occured. Please try again later.";
                        switch(error){
                            case "ExpiredCodeException":
                                message = "Your reset link has expired. Please request a new reset link."
                                break;
                        }
                        setFormData(prevVerifyData=>{
                            return {
                                ...prevVerifyData,
                                isResetting: false,
                                resetFetched:true,
                                resetFailed:true,
                                resetFeedback : message
                            }
                        });
                    }
                    else{
                        setFormData(prevVerifyData=>{
                            return {
                                ...prevVerifyData,
                                isResetting: false,
                                resetFetched:true,
                                resetFailed:false,
                                resetFeedback : "Your password has been changed. We'll redirect you to the login page shortly."
                            }
                        });

                        setTimeout(() => {
                            window.location.href = "/login";
                        }, 3000);

                    }
                }
                else{
                    setFormData(prevVerifyData=>{
                        return {
                            ...prevVerifyData,
                            resetFetched:true,
                            resetFeedback : "Bad or missing parameters."
                        }
                    });
                }
            }
        })
    }

    function renderResetPasswordForm(){

        return(
            <>
            <div className="login-title">Reset your password</div>
                    <form id="form-login" onSubmit={handleSubmit} noValidate>
                                <div id="password-field">
                                    <div className="input-group">
                                        <input 
                                            type={formData.passwordVisible?"text":"password"} 
                                            placeholder="Enter password" 
                                            id="password"
                                            className={`form-control ${(formData.formValidated && !formData.passwordValid)?"is-invalid":""} text-09`}
                                            name="password"
                                            onChange={handleChange}
                                            value={formData.password}
                                        />
                                        <div className="password-visibility" onMouseDown={showPassword} onMouseUp={hidePassword} onMouseLeave={hidePassword}><i className={`bi ${formData.passwordVisible?"bi-eye":"bi-eye-slash"}`}></i></div>
                                    </div>
                                    <div className="invalid-feedback">
                                        {formData.passwordFeedback}
                                    </div>
                                    <div className=" mb-3"></div>
                                </div>

                                <div id="confirm-password-field">
                                    <input 
                                        type="password" 
                                        placeholder="Confirm password" 
                                        id="confirm-password"
                                        className={`form-control ${(formData.formValidated && !formData.confirmedPasswordValid)?"is-invalid":""} text-09`}
                                        name="confirmedPassword"
                                        onChange={handleChange}
                                        value={formData.confirmedPassword}
                                    />
                                    <div className="invalid-feedback">
                                        {formData.confirmedPasswordFeedback}
                                    </div>
                                    <div className=" mb-3"></div>
                                </div>

                            <div>
                                <button type="submit" id="login-submit" className="btn btn-stbx-primary form-control">
                                    {formData.isResetting
                                        ? <div className="spinner-border spinner-border-sm text-light" role="status">
                                        </div>
                                        : <span>Reset Password</span>
                                    }
                                </button>
                            </div>
                    </form>
            </>
        )
    }

    return(
        <div className="flex-container">
            <div>
                <Header/>
                <section className="sc-login p-4">
                    {formData.resetFetched ? <div className="verify-title">{formData.resetFeedback}</div> : renderResetPasswordForm()}
                </section>
            </div>
            <Footer/>
        </div>
    )
}

export default ResetPassword;