const apiUrl =  process.env.REACT_APP_STBX_API_URL || "";
const authPath = process.env.REACT_APP_STBX_AUTH_PATH || "";



export async function forgotPassword(email: string, captchaToken: string){
    const response = await fetch(apiUrl + authPath+"/forgot-password", {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify({"email": email, "captcha_token": captchaToken})
    });
    
    if(!response.ok){
        return {error: "An error has occurred: "+response.status, payload:null};
    }

    const result = await response.json();
    return result;
}

export async function resetPassword(email: string, verification_code: string, password: string){
    const response = await fetch(apiUrl + authPath+"/reset-password", {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify({"email": email, "verification_code":verification_code, "new_password": password})
    });
    
    if(!response.ok){
        return {error: "An error has occurred: "+response.status, payload:null};
    }

    const result = await response.json();
    return result;
}
