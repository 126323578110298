import stbxLogo from '../img/stratbox_logo.png';

function Header (){

    return(
        <div className="header-logo">
            <img src={stbxLogo}></img>
        </div>  
    );
}

export default Header;