import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React from "react";
import { verify } from "../../shared/signup";

function Verify (){

    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [verifyData, setVerifyData] = React.useState(
        {
            isVerifying : false,
            verifyFetched : false,
            emailVerified:false,
            feedbackMessage : ""
        }
    );

    useEffect(() => {
        console.log(searchParams.get('email') || "no email");
        console.log(searchParams.get('verification_code') || "no verification_code");

        if(verifyData.emailVerified){
            console.log("redirecting to login page");
            setTimeout(() => {
                navigate("/login", { replace: true });
            }, 3000);
        }else if(verifyData.isVerifying){
            console.log("calling fetch verifying");
            verifyEmail();
        }
    });

    function renderVerifying(){
        return(
        <form className="form-verify" onSubmit={handleVerify}>
            <h2>Verify your email</h2>
            <b>{searchParams.get('email')}</b>
            <p>Please confirm your email to start using Stratbox.</p>
            <button id="verify" className="btn btn-stbx-primary form-control">
                Click here to verify
            </button>
        </form>)
    }

    function handleVerify(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();
        console.log("verifying");
        setVerifyData(prevVerifyData=>{
            return {
                ...prevVerifyData,
                isVerifying:true
            }
        });
    }

     async function verifyEmail(){
        var email = searchParams.get('email') || "";
        var verification_code = searchParams.get('verification_code') || "";

        if(email && verification_code){
            const error = await verify(email,verification_code);
            if(error){
                setVerifyData(prevVerifyData=>{
                    return {
                        ...prevVerifyData,
                        verifyFetched:true,
                        emailVerified:false,
                        isVerifying:false,
                        feedbackMessage : error
                    }
                });
            }else{
                setVerifyData(prevVerifyData=>{
                    return {
                        ...prevVerifyData,
                        verifyFetched:true,
                        emailVerified:true,
                        isVerifying:false,
                        feedbackMessage : "Thank you for verifying your email address. We'll redirect you to the login page shortly."
                    }
                });
            }
        }else{
            setVerifyData(prevVerifyData=>{
                return {
                    ...prevVerifyData,
                    verifyFetched:true,
                    emailVerified:false,
                    isVerifying:false,
                    feedbackMessage : "Bad or missing parameters."
                }
            });
        }
    }

    return(
        <div className="flex-container">
            <div>
                <Header/>
                <section className="sc-login p-4">
                    {verifyData.verifyFetched ? <div className="verify-title">{verifyData.feedbackMessage}</div> : (verifyData.isVerifying ? <div className="verify-title">Verification...</div> : renderVerifying())}
                </section>
            </div>
            <Footer/>
        </div>
    );
}

export default Verify;