export function redirectToUri(searchParams: URLSearchParams, defaultUri: string){
    let redirect = defaultUri;
    try{
        let domain = new URL(searchParams.get('continue') || "");
        if(domain.hostname.endsWith(".imagedreality.com")) redirect = domain.href;
    }catch(e){}
    window.location.href = redirect;
}

export function validatePassword(password:string){
    return password.match(/^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}()?\-"!@#%&\/\\,><':;|_~`+=])(?=.{8,})/) !== null;
}

export function validateEmail(email:string){
    return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
}

