import { useEffect } from "react";
import { logout } from '../shared/authentication';
import { useNavigate } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";
import React from "react";

function Logout (){

    let navigate = useNavigate();

    const [logoutData, setLogoutData] = React.useState(
        {
            loggingOut : false,
            loggingOutFailed:false,
            loggingOutComplete:false,
            loggingOutFailFeedback:""
        }
    );

    useEffect(() => {
        document.title = 'Log out | Stratbox';
        
        const asyncLogout = async ()=> {
            setLogoutData(prevLogoutData=>{
                return{
                    ...prevLogoutData,
                    loggingOut:true
                }
            });

            const error = await logout();
            if(error){
                setLogoutData(prevLogoutData=>{
                    return{
                        loggingOut:false,
                        loggingOutFailed:true,
                        loggingOutComplete:true,
                        loggingOutFailFeedback:error
                    }
                });
            }else{
                setTimeout(() => {
                    navigate("/login", { replace: true });
                  }, 2000);

                setLogoutData(prevLogoutData=>{
                    return{
                        loggingOut:false,
                        loggingOutFailed:false,
                        loggingOutComplete:true,
                        loggingOutFailFeedback:error
                    }
                });
            }
        }

        if(!logoutData.loggingOut && !logoutData.loggingOutComplete){
            asyncLogout();
        }
      });

    return(
        <div className="flex-container">
            <div>
                <Header/>
                <section className="sc-login p-4">
                    <div className="login-title">{logoutData.loggingOut? "Logging out" : (logoutData.loggingOutFailed ? logoutData.loggingOutFailFeedback : "Successfully logged out")}</div>
                </section>
            </div>
            <Footer/>
        </div>
    );
}

export default Logout;