import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { getInvitation, join } from "../../shared/signup";
import React from "react";
import { validatePassword } from "../../shared/utils";

function Join (){

    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams ();

    
    const [formData, setFormData] = React.useState(
        {
            invitationId: "",
            invitationFetched:false,
            invitationFetchedValid:false,
            invitationFetchedFeedback:"",
            email:"",
            password:"",
            passwordValid:false,
            passwordFeedback:"Please enter your password.",
            confirmedPassword:"",
            confirmedPasswordValid:false,
            confirmedPasswordFeedback:"Please confirm your password.",
            formValidated:false,
            organisationName:"",
            organisationNameValid:false,
            organisationNameFeedback:"Please enter an organisation name.",
            firstName:"",
            firstNameValid:false,
            firstNameFeedback:"Please enter your first name.",
            lastName:"",
            lastNameValid:false,
            lastNameFeedback:"Please enter your last name.",
            isJoining:false,
            joinSucceeded:false,
            joinAttempted:false,
            joinFailedFeedback:"",
            role:"",
            isMultiInvite: false,
        }
    )

    useEffect(() => {
        document.title = 'Join | Stratbox';

        const fetchInvitation = async ()=>{
            var invitation_id = searchParams.get('invitation') || "";

            if(!formData.invitationFetched){
                const {error, invitation} = await getInvitation(invitation_id);
                if(error){
                    setFormData(prevFormData=>{
                        return {
                            ...prevFormData,
                            invitationFetched:true,
                            invitationFetchedValid:false,
                            invitationFetchedFeedback: error
                        }
                    });
                }
                else {
                    console.log(invitation);

                    setFormData(prevFormData=>{
                        return {
                            ...prevFormData,
                            email:invitation.email === ""? "" : invitation.email,
                            role:invitation.role,
                            organisationName: invitation.organisation_name || "",
                            invitationFetched:true,
                            invitationFetchedValid:true,
                            invitationId: invitation_id,
                            isMultiInvite: invitation.email === "",
                        }
                    });
                }
            }
        }

        fetchInvitation()
        .catch(error=>{
            //Unexpected error
            console.log(error);
            setFormData(prevFormData=>{
                return {
                    ...prevFormData,
                    invitationFetched:true,
                    invitationFetchedValid:false,
                    invitationFetchedFeedback: "An error has occurred"
                }
            });
        });
    });
    
    async function handleSubmit(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();
        console.log("submit");
        if(formData.isJoining)return;
            if(validateForm()){
                console.log("form is valid and ready to be sent!");

                setFormData(prevFormData=>{
                    return {
                        ...prevFormData,
                        isJoining: true
                    }
                });

                const result = await join(formData.invitationId,formData.email.trim().toLowerCase(),formData.organisationName,formData.firstName,formData.lastName, formData.password);
                
                console.log(result);
                    setFormData(prevFormData=>{
                        return {
                            ...prevFormData,
                            isJoining: false,
                            joinAttempted:true,
                            joinSucceeded: result.success,
                            joinFailedFeedback: result.error
                        }
                    });
            }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>){
        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }


    function validateForm(){
        var organisationNameValid = (formData.role === "admin") ? (formData.organisationName.trim().length > 0) : true;
        var firstNameValid = formData.firstName.trim().length > 0;
        var lastNameValid = formData.lastName.trim().length > 0;
        var passwordValid = false;
        var passwordFeedback = "Please enter a password."
        var confirmedPasswordValid = false;
        var confirmedPasswordFeedback = "Please confirm your password."

        if(formData.password.length > 0){
            passwordValid = validatePassword(formData.password); 
            if(!passwordValid) passwordFeedback = "You password must contain at least 8 characters (one digit, one upper case, one lower case and one special character, no spaces).";
        }

        if(formData.confirmedPassword.length > 0){
            confirmedPasswordValid = (formData.confirmedPassword === formData.password);
            if(!confirmedPasswordValid) confirmedPasswordFeedback = "Passwords must match";
        }

        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                formValidated: true,
                organisationNameValid: organisationNameValid,
                firstNameValid: firstNameValid,
                lastNameValid: lastNameValid,
                passwordValid:passwordValid,
                passwordFeedback:passwordFeedback,
                confirmedPasswordValid:confirmedPasswordValid,
                confirmedPasswordFeedback:confirmedPasswordFeedback
            }
        });

        return (organisationNameValid && firstNameValid && lastNameValid && passwordValid && confirmedPasswordValid);
    }

    function renderJoinForm(){
        return(
        <div>
            <div className="login-title">You've been invited to join {formData.role==="admin"?"a new organisation (as Administrator)": formData.organisationName}</div>
                    <div className=" mb-3"></div>
                    <form id="form-join" onSubmit={handleSubmit} noValidate>
                            {formData.role==="admin" &&
                                <div className="form-group">
                                    <label htmlFor="organisationName">Choose an organisation name</label>
                                    <div className=" mb-2"></div>
                                    <input 
                                        type="text" 
                                        id="organisationName" 
                                        className={`form-control ${(formData.formValidated && !formData.organisationNameValid)?"is-invalid":""} text-09`}
                                        name="organisationName"
                                        placeholder="Organisation name"
                                        onChange={handleChange}
                                        value={formData.organisationName}
                                        />
                                    <div className="invalid-feedback">
                                        {formData.organisationNameFeedback}
                                    </div>
                                    <div className=" mb-3"></div>
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="email-field">Create an account</label>
                                <div className=" mb-2"></div>
                                <div id="email-field">
                                    <input 
                                        type="email" 
                                        id="email"
                                        className={`form-control text-09`}
                                        name="email"
                                        placeholder="Email"
                                        onChange={(e) => {
                                            if (!formData.isMultiInvite)
                                                return
                                            else handleChange(e)
                                        }}
                                        value={formData.email}
                                        disabled={!formData.isMultiInvite}
                                    />
                                    <div className=" mb-3"></div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                    <input 
                                        type="text" 
                                        id="firstName"
                                        className={`form-control ${(formData.formValidated && !formData.firstNameValid)?"is-invalid":""} text-09`} 
                                        name="firstName"
                                        placeholder="First name"
                                        onChange={handleChange}
                                        value={formData.firstName}
                                        />
                                    <div className="invalid-feedback">
                                        {formData.firstNameFeedback}
                                    </div>
                                    </div>
                                    <div className="col">
                                    <input 
                                        type="text" 
                                        id="last-name"
                                        className={`form-control ${(formData.formValidated && !formData.lastNameValid)?"is-invalid":""} text-09`} 
                                        name="lastName"
                                        placeholder="Last name"
                                        onChange={handleChange}
                                        value={formData.lastName}
                                        />
                                    <div className="invalid-feedback">
                                        {formData.lastNameFeedback}
                                    </div>
                                    </div>
                                    <div className=" mb-3"></div>
                                </div>
                            
                                <div id="password-field">
                                    <input 
                                        type="password" 
                                        placeholder="Enter password" 
                                        id="password"
                                        className={`form-control ${(formData.formValidated && !formData.passwordValid)?"is-invalid":""} text-09`}
                                        name="password"
                                        onChange={handleChange}
                                        value={formData.password}
                                    />
                                    <div className="invalid-feedback">
                                        {formData.passwordFeedback}
                                    </div>
                                    <div className=" mb-3"></div>
                                </div>

                                <div id="confirm-password-field">
                                    <input 
                                        type="password" 
                                        placeholder="Confirm password" 
                                        id="confirm-password"
                                        className={`form-control ${(formData.formValidated && !formData.confirmedPasswordValid)?"is-invalid":""} text-09`}
                                        name="confirmedPassword"
                                        onChange={handleChange}
                                        value={formData.confirmedPassword}
                                    />
                                    <div className="invalid-feedback">
                                        {formData.confirmedPasswordFeedback}
                                    </div>
                                    <div className=" mb-3"></div>
                                </div>
                            </div>
                            <div>
                                <button type="submit" id="login-submit" className="btn btn-stbx-primary form-control">
                                    {formData.isJoining
                                        ? <div className="spinner-border spinner-border-sm text-light" role="status">
                                        </div>
                                        : <span>Join</span>
                                    }
                                </button>
                            </div>
                        </form>
        </div>);
    }

    function renderJoinFeedback(){
        if(formData.joinSucceeded){
            return(
                <div>
                    <div className="login-title">Thank you for signing up!</div>
                    <br></br>
                    <p>An email has been sent to <b>{formData.email}</b> with instructions for verifying your account.</p>
                </div>
            );
        }else{
            return(
                <div>
                    <div className="login-title">An error occured. Please try again.</div>
                    <br></br>
                    <p>{formData.joinFailedFeedback}</p>
                </div>
            );
        }
    }

    function renderPage(){
        if(formData.invitationFetched){
            if(formData.invitationFetchedValid){
                return formData.joinAttempted?renderJoinFeedback():renderJoinForm()
            }else{
                return(
                    <div>
                        <div className="login-title">{formData.invitationFetchedFeedback}</div>
                    </div>
                )
            }
        }else{
            return <div></div>
        }
    }
    
    return(
        <div className="flex-container">
            <div>
                <Header/>
                <section className="sc-login p-4">
                    {renderPage()}
                </section>
            </div>
            <Footer/>
        </div>
    );
}

export default Join;